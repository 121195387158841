
html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background: #EEEEEE 0% 0% no-repeat padding-box;
  /* font-family: 'Roboto, Sans-serif';
  font-family: 'Roboto';
  font-family: 'Segoe UI'; */
  text-align: left;

  font: normal normal normal 16px/24px "Mustica Pro";
  letter-spacing: 0.14px;
  color: #858585;
  overflow: hidden;
}

.main-container {
  height: calc(var(--vh, 1vh) * 100);
}

@media print {
  .main-container {
    height: auto;
  }
}

@page {
  /* size: auto; */
  size: A4;
  margin: 0;
}

.hide-main-content {
  display: none;
}

@media print {
  html, body {
    height: auto;
    overflow: auto;
    width: 1200px;
  }

  table {
    break-inside: avoid;
  }
  tr {
    break-inside: avoid;
  }
}

h1, .h1, h2, .h2 {
  font: normal normal normal 24px/24px "Mustica Pro";
  letter-spacing: 0.22px;
  color: #3A817F;
}

h3, .h3 {
  font: normal normal normal 16px/24px Roboto;
  letter-spacing: 0.14px;
  color: #676767;
}

* {
  box-sizing: border-box;
  /* outline: 1px solid rosybrown; */
  /* background: rgba(0, 100, 0, 0.05) !important; */
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #858585;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #505050;
}

/* Firefox */
* {
  scrollbar-color: #858585 #EEEEEE !important;
  scrollbar-width: thin !important;
}

.hide{
  visibility: hidden;
  height: 1px;
}

/* https://www.youtube.com/watch?v=YAqRQoN8ykI */
/* WARN: Safari needs a container with 'position: relative' for the .visually-hidden class */
.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

/*
 Hack to remove row shadows on Safari, because
 Safari ignores 'position: relative' on table row (tr) elements
*/
_::-webkit-full-page-media, _:future, :root .disable-on-safari {
  display: none !important;
}
@media not all and (min-resolution:.001dpcm) {
  @media {
    .disable-on-safari {
      display: none !important;
    }
  }
}

.unity-web-view .disable-on-safari, .unity-web-view .hide-in-unity {
  display: none !important;
}

.required {
  color: #E0758A;
}
